import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import './index.css';
import theme from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID as string,
    authority: process.env.REACT_APP_AD_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
};

const pca = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MsalProvider instance={pca}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </MsalProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
