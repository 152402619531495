import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext<{
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>({ loading: false, setLoading: () => null });

export const useLoadingContext = () => useContext(LoadingContext);

interface Props {
  children: React.ReactNode;
}

export default function LoadingContextProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}
