import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Activities, Attendance, SignIn } from 'pages';
import { LoadingContextProvider, UserContextProvider } from 'context';
import { Layout } from 'components';

function App() {
  return (
    <UserContextProvider>
      <LoadingContextProvider>
        <UnauthenticatedTemplate>
          <SignIn />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Layout>
            <Routes>
              <Route path="/" element={<Attendance />} />
              <Route path="/activities">
                <Route path="planned" element={<Activities isPlanned />} />
                <Route path="unplanned" element={<Activities />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Layout>
        </AuthenticatedTemplate>
      </LoadingContextProvider>
    </UserContextProvider>
  );
}

export default App;
