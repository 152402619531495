import { useEffect } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useMutation } from '@tanstack/react-query';
import { useLoadingContext } from 'context';
import { getAccessToken } from 'utils/getAccessToken';

interface MutateArgs<T> {
  url: string;
  data?: T;
  method: 'POST' | 'PATCH' | 'DELETE';
}

const useMutate = <T = any>() => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { setLoading } = useLoadingContext();

  const mutation = useMutation(async ({ url, data, method }: MutateArgs<T>) => {
    const accessToken = await getAccessToken(instance, account);

    const config = {
      method,
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    return fetch(process.env.REACT_APP_DB_URL + url, config);
  });

  useEffect(() => {
    setLoading(mutation.isLoading);
  }, [mutation.isLoading, setLoading]);

  return [mutation.mutateAsync, mutation] as const;
};

export default useMutate;
