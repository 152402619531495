import { Button, Card, CardContent, Typography } from '@mui/material';
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';

export default function SignIn() {
  const { login } = useMsalAuthentication(InteractionType.None);

  return (
    <Card
      sx={{
        width: '90vw',
        maxWidth: 400,
        margin: 'auto',
        marginTop: '4rem',
        position: 'relative',
      }}
    >
      <CardContent
        sx={{ padding: '2rem', display: 'grid', placeItems: 'center' }}
      >
        <img
          src="/images/ql_logo_green.png"
          alt=""
          height={64}
          style={{ marginBottom: '1rem' }}
        />
        <Typography textAlign="center" mb={2} fontWeight={700} variant="h5">
          Event Calendar
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="success"
          onClick={() => login(InteractionType.Redirect)}
        >
          Sign in
        </Button>
      </CardContent>
    </Card>
  );
}
