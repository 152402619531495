import { createContext, useContext } from 'react';

export interface IActivityContext {
  openEditModal: (id: string) => void;
  handleUpdate: (userId: string, activityId: string, name: string) => void;
  handleSave: (values: Record<string, any>) => Promise<void>;
  handleDelete: () => Promise<void>;
  openDeleteModal: (id: string) => void;
}

export const ActivityContext = createContext<IActivityContext>(
  {} as IActivityContext
);

export const useActivityContext = () => useContext(ActivityContext);
