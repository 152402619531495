import { useCallback, useState } from 'react';
import { Add } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ActivityContext } from 'context/ActivityContext';
import { ActivityList, DeleteModal, EditModal } from 'components';
import { useFetch, useMutate } from 'hooks';
import { Offices } from 'types';

export interface Activity {
  activityName: string;
  date: string;
  time: string;
  details: string;
  img: string;
  attendees: { _id: string; userId: string; username: string }[];
  office: Offices;
  _id: string;
}

interface Props {
  isPlanned?: boolean;
}

export default function Activities({ isPlanned = false }: Props) {
  const { data = [], refetch } = useFetch<Activity[]>({
    url: isPlanned ? 'activity/planned' : 'activity/unplanned',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [mutate] = useMutate();

  const handleUpdate = async (
    userId: string,
    activityId: string,
    name: string
  ) => {
    await mutate({
      method: 'PATCH',
      url: `activity/edit-attending-status/${activityId}`,
      data: { userId, name },
    });
    refetch();
  };

  const handleSave = useCallback(
    async (values: Record<string, any>) => {
      let response;
      try {
        // Update existing activity
        if (!!selectedId) {
          response = await mutate({
            method: 'PATCH',
            url: `activity/edit/${selectedId}`,
            data: values,
          });
          // Create new activity
        } else {
          response = await mutate({
            method: 'POST',
            url: `activity/create`,
            data: values,
          });
        }
      } catch (error: any) {
        console.log(error);
      }

      if (response?.status === 200) {
        setIsDeleteOpen(false);
        setIsOpen(false);
        refetch();
      } else {
        console.log('something went wrong');
      }
    },
    [selectedId, refetch, mutate]
  );

  const handleDelete = useCallback(async () => {
    try {
      await mutate({ method: 'DELETE', url: `activity/delete/${selectedId}` });
      setIsDeleteOpen(false);
      setIsOpen(false);
      refetch();
    } catch (error: any) {
      console.log(error);
    }
  }, [selectedId, mutate, refetch]);

  const openDeleteModal = (activityId: string) => {
    setSelectedId(activityId);
    setIsDeleteOpen(true);
  };

  const openEditModal = (activityId: string) => {
    setIsDeleteOpen(false);
    setSelectedId(activityId);
    setIsOpen(true);
  };

  return (
    <ActivityContext.Provider
      value={{
        handleSave,
        handleDelete,
        handleUpdate,
        openEditModal,
        openDeleteModal,
      }}
    >
      <IconButton
        onClick={() => openEditModal('')}
        size="large"
        sx={{
          zIndex: 1,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
          background: 'white',
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',

          '&:hover': {
            background: '#77dd77',
          },
        }}
      >
        <Add />
      </IconButton>
      <Typography
        key={isPlanned.toString()}
        sx={{ paddingLeft: matches ? '1.5rem' : '0.5rem' }}
        color="white"
        style={{ animation: 'fadeIn 250ms' }}
        variant="h5"
        fontWeight={700}
      >
        {isPlanned ? "It's on" : 'Make it happen'}
      </Typography>
      <Grid
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        px={matches ? 3 : 1}
        py={1}
        height="100%"
        overflow="auto"
        container
        wrap="wrap"
        gap={matches ? 3 : 1}
        sx={{ maxHeight: '75vh' }}
      >
        <ActivityList {...{ data }} />
      </Grid>
      <EditModal
        isCreate={!!selectedId === false}
        isPlanned={isPlanned}
        defaultValue={data.find((item) => item._id === selectedId) || null}
        onSave={handleSave}
        onClose={() => setIsOpen(false)}
        onDelete={() => setIsDeleteOpen(true)}
        open={isOpen}
      />
      <DeleteModal
        open={isDeleteOpen}
        onDelete={handleDelete}
        onClose={() => setIsDeleteOpen(false)}
        onEdit={() => openEditModal(selectedId)}
      />
    </ActivityContext.Provider>
  );
}
