import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'unset',
  gap: theme.spacing(2),
}));

interface Props {
  open: boolean;
  onClose?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

const DeleteModal = ({ open, onClose, onDelete, onEdit }: Props) => {
  return (
    <Dialog
      onClose={() => {
        onClose?.();
      }}
      open={open}
      fullWidth
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onDelete?.();
        }}
      >
        <DialogTitle>Remove activity</DialogTitle>
        <StyledDialogContent>
          <Typography>Are you sure?</Typography>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onEdit}>Edit</Button>
          <Button type="submit" variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteModal;
