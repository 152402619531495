import React from 'react';
import { Chip } from '@mui/material';
import { useLoadingContext } from 'context';
import { useActivityContext } from 'context/ActivityContext';
import { ActivityListProps } from 'components/ActivityList/types';
import {
  DateTime,
  dateAndTimeToMilliseconds,
} from 'utils/dateAndTimeToMilliseconds';
import ActivityListItemNotPlanned from './ActivityListItemNotPlanned';
import ActivityListItemPlanned from './ActivityListItemPlanned';

const Animate = ({ children }: { children: React.ReactNode }) => (
  <div style={{ animation: 'fadeIn 250ms ease-in-out' }}>{children}</div>
);

const sortByDate = (a: DateTime, b: DateTime) =>
  dateAndTimeToMilliseconds(a) - dateAndTimeToMilliseconds(b);

const filterByDateLessThanNow = (item: DateTime) =>
  dateAndTimeToMilliseconds(item) < new Date().getTime();

const filterByDateGreaterThanNow = (item: DateTime) =>
  !!item.date === false
    ? true
    : dateAndTimeToMilliseconds(item) > new Date().getTime();

export default function ActivityList({ data }: ActivityListProps) {
  const { openEditModal } = useActivityContext();
  const { loading } = useLoadingContext();

  if (loading && data.length === 0) {
    return null;
  }

  if (!data.length) {
    return (
      <Animate>
        <Chip
          onClick={() => openEditModal('')}
          color="success"
          label="Nothing here. Click me to plan!"
        />
      </Animate>
    );
  }

  const sortedData = data.sort(sortByDate);

  return (
    <>
      {[
        ...sortedData.filter(filterByDateGreaterThanNow),
        ...sortedData.filter(filterByDateLessThanNow),
      ].map(({ activityName, time, date, img, attendees, office, _id }) => {
        return !!date ? (
          <Animate key={_id}>
            <ActivityListItemPlanned
              {...{
                activityName,
                time,
                date,
                img,
                _id,
                attendees,
                office,
              }}
            />
          </Animate>
        ) : (
          <Animate key={_id}>
            <ActivityListItemNotPlanned {...{ _id, activityName, img }} />
          </Animate>
        );
      })}
    </>
  );
}
