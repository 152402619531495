import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AttendanceCard } from 'components';
import { useFetch, useMutate } from 'hooks';
import { OFFICES } from 'consts';
import { Offices } from 'types';

const Attendance = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedOffice, setSelectedOffice] = useState<Offices>(
    (searchParams.get('office') as Offices) || 'stockholm'
  );

  useEffect(() => {
    setSelectedOffice((searchParams.get('office') as Offices) || 'stockholm');
  }, [searchParams]);

  const { data = [], refetch } = useFetch<
    {
      _id: string;
      office: Offices;
      name: string;
      users: { name: string; id: string }[];
    }[]
  >({
    url: `attendance/${selectedOffice}`,
  });

  const [mutate] = useMutate();

  const handleUpdate = async (userId: string, id: string, name: string) => {
    await mutate({
      method: 'POST',
      url: `attendance/${id}`,
      data: { userId, name },
    });
    refetch();
  };

  const handleOfficeChange = (office: Offices) => {
    setSelectedOffice(office);
    navigate({
      search: createSearchParams({
        office,
      }).toString(),
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Typography mb={2} fontWeight={700} variant="h5" color="white">
          Castle attendance
        </Typography>
        <ButtonGroup variant="outlined">
          {OFFICES.map((office) => (
            <Button
              color="success"
              key={office}
              onClick={() => handleOfficeChange(office)}
              variant={selectedOffice === office ? 'contained' : undefined}
            >
              {office}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Grid
        gap={2}
        p={2}
        container
        justifyContent="center"
        overflow="auto"
        sx={{ maxHeight: '65vh' }}
      >
        {data?.map((day, index) => (
          <AttendanceCard key={day._id} {...{ day, index, handleUpdate }} />
        ))}
      </Grid>
    </>
  );
};

export default Attendance;
