import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CalendarMonthOutlined, Logout } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAccount, useMsal } from '@azure/msal-react';
import { useLoadingContext } from 'context';
import AnimateBase from 'components/AnimateBase';

const animateStyle = { width: 30, display: 'grid', placeItems: 'center' };

export default function Header() {
  const { loading } = useLoadingContext();
  const location = useLocation();
  const isPlanned = location.pathname === '/activities/planned';
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <AppBar elevation={0} sx={{ backgroundColor: 'transparent' }}>
      <LinearProgress
        style={{ transitionDuration: '250ms', opacity: loading ? 1 : 0 }}
        color="success"
      />
      <Toolbar
        sx={{
          width: '100%',
          maxWidth: 1400,
          margin: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          display="grid"
          alignItems="space-between"
          gridTemplateColumns="1fr auto 1fr"
          width="100%"
        >
          <Grid container alignItems="center">
            <Link to={location.pathname !== '/' ? '/' : '/activities/planned'}>
              {location.pathname === '/' ? (
                <AnimateBase style={animateStyle} key="1">
                  <img src="/images/ql_logo_green.png" alt="" width={18} />
                </AnimateBase>
              ) : (
                <AnimateBase style={animateStyle} key="2">
                  <CalendarMonthOutlined
                    color="success"
                    sx={(theme) => ({
                      fontSize: '2rem',
                    })}
                  />
                </AnimateBase>
              )}
            </Link>
            {matches && (
              <Typography variant="button" ml={2}>
                {account?.name}
              </Typography>
            )}
          </Grid>
          <div
            style={{ display: 'grid', placeItems: 'center', gap: '0.25rem' }}
          >
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                fontSize: 9,
                borderRadius: '0.25rem',
                transitionDuration: '250ms',
                color: isPlanned ? theme.qlPrimary : '#fff',
                transform: isPlanned
                  ? 'translateY(30px) scale(1.55)'
                  : 'translateY(2px)',
              }}
            >
              Make it happen!
            </div>
            <Button
              variant="outlined"
              color="success"
              sx={{
                width: 150,
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                navigate(`/activities/${isPlanned ? 'unplanned' : 'planned'}`);
              }}
            >
              <div
                style={{
                  color: !isPlanned ? 'inherit' : '#fff',
                  textTransform: 'initial',
                  transitionDuration: '250ms',
                  pointerEvents: isPlanned ? 'none' : 'unset',
                  transform: isPlanned
                    ? 'translateY(-27px) scale(0.642)'
                    : 'translateY(0px) scale(1)',
                }}
              >
                It's on
              </div>
            </Button>
          </div>

          {!matches ? (
            <IconButton
              sx={{ justifySelf: 'end' }}
              edge="end"
              color="inherit"
              onClick={() => instance.logoutRedirect()}
            >
              <Logout />
            </IconButton>
          ) : (
            <Button
              sx={{ justifySelf: 'end' }}
              color="inherit"
              onClick={() => instance.logoutRedirect()}
            >
              Sign out
            </Button>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
