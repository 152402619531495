import { useEffect } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useLoadingContext } from 'context';
import { getAccessToken } from 'utils/getAccessToken';

interface Options {
  url: string;
}

const useFetch = <T = any>({ url }: Options) => {
  const { setLoading } = useLoadingContext();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const query = useQuery([url], async (): Promise<T> => {
    const accessToken = await getAccessToken(instance, account);

    return fetch(process.env.REACT_APP_DB_URL + url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((r) => r.json());
  });

  useEffect(() => {
    setLoading(query.isLoading || query.isFetching);
  }, [query.isLoading, query.isFetching, setLoading]);

  return query;
};

export default useFetch;
