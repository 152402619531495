import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { useUserContext } from 'context';
import { InitialsAvatar } from 'components';
import { LOCALE } from 'consts';
import { Offices } from 'types';

type Day = {
  _id: string;
  office: Offices;
  name: string;
  users: { name: string; id: string }[];
};

interface AttendanceCardProps {
  day: Day;
  index: number;
  handleUpdate: (userId: string, id: string, name: string) => Promise<void>;
}

const AVATAR_CUTOFF = 4;
const AVATAR_OFFSET = 0.55;

const Animate = ({ children }: { children: React.ReactNode }) => (
  <div style={{ animation: 'fadeIn 250ms ease-in-out' }}>{children}</div>
);

const getCurrentDate = (n: number) => {
  const date = new Date();
  const diff = date.getDay() - n;

  date.setDate(date.getDate() - diff);

  return date.toLocaleDateString(LOCALE, { day: '2-digit', month: '2-digit' });
};

const AttendanceCard = ({ day, index, handleUpdate }: AttendanceCardProps) => {
  const { localAccountId: userId = '', name = '' } = useUserContext() || {};
  const users = day.users || [];
  const isCurrentUserAttending = day.users.find((item) => item.id === userId);
  const personsAmount = users.length;

  const currentDayIndex = new Date().getDay();
  let dayIndex = index + 1;

  // After 15:00 on fridays CRON job will reset attendance
  // dayIndex is modified friday 15:00 -> sunday 23:59 to
  // reflect next weeks dates until next week begins.
  if ((currentDayIndex === 5 && new Date().getHours() > 15) || dayIndex > 5) {
    dayIndex += 7;
  }

  const isToday = currentDayIndex === dayIndex;

  const avatarStyle = (theme: Theme) => ({
    ...(isToday && {
      backgroundColor: '#fff',
      borderColor: theme.qlPrimary,
    }),
  });

  return (
    <Animate key={day._id}>
      <Grid
        item
        sx={{
          minWidth: 200,
          height: 200,
        }}
      >
        <Card
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: isToday ? theme.qlPrimary : '#fff',
          })}
        >
          <CardContent>
            <Grid container>
              <Typography fontWeight={700} mb={2}>
                {day.name}
              </Typography>
              <Typography variant="caption" ml="auto">
                {getCurrentDate(dayIndex)}
              </Typography>
            </Grid>
            <Typography mb={2} variant="caption">
              {personsAmount > 0 && (
                <span style={{ fontWeight: 700 }}>{personsAmount}</span>
              )}
              {personsAmount > 0
                ? ` queen${
                    personsAmount > 1
                      ? 's at the castle'
                      : ' is ruling all alone'
                  } `
                : '🏰 is empty today...'}
            </Typography>
            <Box ml="-2px" display="flex">
              {Object.entries(users)
                .slice(0, AVATAR_CUTOFF)
                .map((item, index) => {
                  const [userId, values] = item;

                  return (
                    <InitialsAvatar
                      key={userId}
                      name={values.name || ''}
                      avatarStyle={avatarStyle}
                      style={{
                        marginLeft:
                          index !== 0 ? `-${AVATAR_OFFSET}rem` : 'unset',
                        zIndex: personsAmount - index,
                      }}
                    />
                  );
                })}
              {Object.keys(users).length - AVATAR_CUTOFF > 0 && (
                <InitialsAvatar
                  avatarStyle={avatarStyle}
                  name={`+ ${Object.keys(users).length - AVATAR_CUTOFF}`}
                  toolTipTitle={Object.values(users)
                    .slice(AVATAR_CUTOFF)
                    .map((item, index) => (
                      <div key={item.name + index}>{item.name}</div>
                    ))}
                  style={{
                    marginLeft: `-${AVATAR_OFFSET}rem`,
                    zIndex: 0,
                  }}
                />
              )}
            </Box>
          </CardContent>
          <CardActions>
            <Button
              variant={isCurrentUserAttending ? 'text' : 'contained'}
              color={isCurrentUserAttending ? 'error' : 'primary'}
              onClick={() => handleUpdate(userId, day._id, name)}
            >
              {isCurrentUserAttending ? 'Leave' : 'Attend'}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Animate>
  );
};

export default AttendanceCard;
