import { useMediaQuery, useTheme } from '@mui/material';
import { Header } from 'components';

export default function Layout({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Header />
      <div
        style={{
          maxWidth: 1400,
          margin: 'auto',
          marginTop: '6rem',
          padding: matches ? '0 1.5rem' : '0 0.5rem',
        }}
      >
        {children}
      </div>
    </>
  );
}
