import { createContext, useContext } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';

const UserContext = createContext<AccountInfo | null>(null);

export const useUserContext = () => useContext(UserContext);

interface Props {
  children: React.ReactNode;
}

export default function UserContextProvider({ children }: Props) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  return (
    <UserContext.Provider value={account}>{children}</UserContext.Provider>
  );
}
