import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export const getAccessToken = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  const { accessToken } = await instance.acquireTokenSilent({
    scopes: [`${process.env.REACT_APP_AD_CLIENT_ID}/.default`],
    account: account || undefined,
  });

  return accessToken;
};
