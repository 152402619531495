import React from 'react';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const AnimateBase = ({ children, style }: Props) => (
  <div
    style={{
      animation: 'fadeIn 250ms ease-in-out',
      ...style,
    }}
  >
    {children}
  </div>
);

export default AnimateBase;
