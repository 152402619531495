import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = {
  qlPrimary: '#96FA9D',
  qlBgPrimary: '#232a52',
  qlBgPrimaryDark: '#121527',
  qlSecondary: '#121527',
  qlError: '#ed2e7e',
} as const;

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme({
  ...theme,
  palette: {
    success: {
      main: theme.qlPrimary,
    },
    error: {
      main: theme.qlError,
    },
    primary: {
      main: theme.qlBgPrimary,
    },
  },
});
