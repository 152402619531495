import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material';
import { Activity } from 'pages';
import { OFFICES } from 'consts';

interface Props {
  isCreate: boolean;
  open: boolean;
  isPlanned: boolean;
  defaultValue: Activity | null;
  onClose?: () => void;
  onSave?: (values: any) => void;
  onDelete?: () => void;
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'unset',
  gap: theme.spacing(2),
}));

export default function EditModal({
  isCreate,
  open,
  defaultValue,
  onClose,
  onDelete,
  onSave,
  isPlanned,
}: Props) {
  const [values, setValues] = useState<Record<string, any>>({});

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onSubmit = (event: React.FormEvent) => {
    event?.preventDefault();
    onSave?.(values);
  };

  useEffect(() => {
    if (open) {
      setValues((prev) => ({ ...prev, img: defaultValue?.img || '' }));
    }
  }, [defaultValue?.img, open]);

  return (
    <Dialog
      onClose={() => {
        onClose?.();
      }}
      open={open}
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{isCreate ? 'Add activity' : 'Edit activity'}</DialogTitle>
        <StyledDialogContent>
          <TextField
            required
            defaultValue={defaultValue?.activityName}
            name="activityName"
            onChange={handleChange}
            variant="outlined"
            label="Title"
          />
          <TextField
            defaultValue={defaultValue?.img || ''}
            name="img"
            onChange={handleChange}
            variant="outlined"
            label="Image link"
          />
          <img
            style={{
              margin: 'auto',
              backgroundColor: values?.img ? 'unset' : 'lightgray',
              width: 150,
              height: 100,
              objectFit: 'contain',
            }}
            src={values?.img || ''}
            alt=""
          />
          <TextField
            onChange={handleChange}
            name="details"
            defaultValue={defaultValue?.details}
            variant="outlined"
            label="Description"
            multiline
            rows={3}
          />
          <Grid container gap={2}>
            {!isPlanned && (
              <Grid xs item>
                <Chip color="warning" label="Set a date to make it happen!" />
              </Grid>
            )}
            <Grid item container gap={2}>
              <Grid item xs>
                <TextField
                  onChange={handleChange}
                  name="date"
                  defaultValue={defaultValue?.date || ''}
                  fullWidth
                  variant="outlined"
                  type="date"
                  onBlur={(event) => {
                    if (
                      new Date(event.target.value).getTime() <
                      new Date(
                        new Date().toISOString().split('T')[0].toString()
                      ).getTime()
                    ) {
                      event.target.value = '';
                      setValues((prev) => {
                        delete prev[event.target.name];
                        return prev;
                      });
                    }
                  }}
                  inputProps={{
                    min: new Date().toISOString().split('T')[0].toString(),
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  onChange={handleChange}
                  name="time"
                  defaultValue={defaultValue?.time}
                  fullWidth
                  variant="outlined"
                  type="time"
                />
              </Grid>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="office">Office</InputLabel>
                <Select
                  labelId="office"
                  id="office"
                  name="office"
                  label="Office"
                  onChange={handleChange}
                  defaultValue={defaultValue?.office || ''}
                  fullWidth
                >
                  {OFFICES.map((office) => (
                    <MenuItem
                      key={office}
                      sx={{ textTransform: 'capitalize' }}
                      value={office}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        {office}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          {!isCreate && (
            <Button onClick={onDelete} variant="contained" color="error">
              Delete
            </Button>
          )}
          <Button type="submit" variant="contained">
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
