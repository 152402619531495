import { useState } from 'react';
import { Avatar, Theme, Tooltip } from '@mui/material';

const getInitials = (name: string) => {
  if (name === '') return '';

  const [firstName, lastName] = name.split(' ');
  return ((firstName?.[0] || '') + (lastName?.[0] || '')).toUpperCase();
};

const InitialsAvatar = ({
  name = '',
  toolTipTitle,
  avatarStyle,
  ...rest
}: {
  name: string;
  toolTipTitle?: React.ReactNode;
  avatarStyle?: ((theme: Theme) => React.CSSProperties) | React.CSSProperties;
  [rest: string]: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      {...rest}
    >
      <Tooltip
        arrow
        title={toolTipTitle || name}
        open={isOpen}
        placement="bottom"
      >
        <Avatar
          sx={(theme) => ({
            backgroundColor: theme.qlPrimary,
            color: '#000',
            border: '2px solid #fff',
            fontSize: 14,
            ...(typeof avatarStyle === 'function'
              ? avatarStyle?.(theme)
              : avatarStyle),
          })}
          variant="circular"
        >
          {getInitials(name)}
        </Avatar>
      </Tooltip>
    </div>
  );
};

export default InitialsAvatar;
