import { Button, Grid, Typography } from '@mui/material';
import { Activity } from 'pages';
import { useActivityContext } from 'context/ActivityContext';

interface Props extends Pick<Activity, 'activityName' | 'img' | '_id'> {}

const IMAGE_HEIGHT = 100;

export default function ActivityListItemNotPlanned({
  activityName,
  img,
  _id: activityId,
}: Props) {
  const { openEditModal } = useActivityContext();
  return (
    <Grid
      item
      container
      display="grid"
      gridTemplateColumns={`${IMAGE_HEIGHT}px 1fr`}
      sx={{ opacity: 0.98, background: 'white', padding: '1rem' }}
      borderRadius={1}
      overflow="hidden"
    >
      <Grid
        borderRadius={16}
        overflow="hidden"
        height={IMAGE_HEIGHT}
        width={IMAGE_HEIGHT}
        item
      >
        <img
          alt=""
          style={{
            objectFit: img ? 'cover' : 'contain',
            width: '100%',
            height: '100%',
          }}
          src={img || '/images/ql_logo_green.png'}
        />
      </Grid>
      <Grid overflow="hidden" gap={1} item container p={2}>
        <Grid width="100%" item>
          <Typography
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            variant="h6"
            fontWeight={700}
          >
            {activityName}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              type="button"
              onClick={() => openEditModal(activityId)}
            >
              Make it happen!
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
