import { useState } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { Activity } from 'pages';
import { useUserContext } from 'context';
import { useActivityContext } from 'context/ActivityContext';
import { ImagePlaceholder } from 'components';
import { dateAndTimeToMilliseconds } from 'utils/dateAndTimeToMilliseconds';
import { LOCALE } from 'consts';

interface Props extends Omit<Activity, 'details'> {}

const IMAGE_HEIGHT = 150;

export default function ActivityListItemPlanned({
  activityName,
  time,
  date,
  img,
  attendees,
  office,
  _id: activityId,
}: Props) {
  const [isHover, setIsHover] = useState(false);
  const { openEditModal, handleUpdate, openDeleteModal } = useActivityContext();
  const { localAccountId: userId = '', name = '' } = useUserContext() || {};
  const attendeeList = attendees.map((item) => item.username).join(', ');
  const isAttending = attendees.map((item) => item.userId).includes(userId);

  const isOver =
    new Date().getTime() > dateAndTimeToMilliseconds({ date, time });

  return (
    <Grid
      item
      container
      position="relative"
      flexDirection="column"
      sx={{ opacity: 0.98, background: 'white' }}
      key={activityId}
      borderRadius={1}
      overflow="hidden"
    >
      {isOver && (
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => openDeleteModal(activityId)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1,
          }}
        >
          <Typography
            sx={(theme) => ({
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              zIndex: 1,
              display: 'grid',
              placeItems: 'center',
              gap: 2,
              fontWeight: 700,
              color: theme.qlPrimary,
              textAlign: 'center',
            })}
          >
            {isHover ? (
              <DeleteForever sx={{ height: 48, width: '100%' }} />
            ) : (
              <img src="/images/ql_logo_green.png" alt="" height={48} />
            )}

            <span>{isHover ? 'REMOVE EVENT' : 'EVENT CONCLUDED'}</span>
          </Typography>
        </div>
      )}
      <Grid height={IMAGE_HEIGHT} item>
        {!!img ? (
          <img
            alt=""
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            src={img}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </Grid>
      <Grid gap={1} flexDirection="column" container p={2}>
        <Grid item width="100%">
          <Typography
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            variant="h6"
            fontWeight={700}
          >
            {activityName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="GrayText"
            variant="body2"
            textTransform="capitalize"
          >
            {new Date(date).toLocaleDateString(LOCALE, {
              day: 'numeric',
              month: 'short',
              weekday: 'long',
            })}
          </Typography>
          <Typography color="GrayText" variant="body2">
            {office && (
              <span style={{ textTransform: 'capitalize' }}>{office}</span>
            )}
            <span> {time || '-'}</span>
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              disabled={isOver}
              onClick={() => handleUpdate(userId, activityId, name)}
              variant={isAttending ? 'outlined' : 'contained'}
              color={isAttending ? 'error' : 'primary'}
              sx={(theme) => ({
                backgroundColor: !isAttending ? theme.qlBgPrimary : 'inherit',

                '&:hover': {
                  backgroundColor: !isAttending
                    ? theme.qlBgPrimaryDark
                    : 'inherit',
                },
              })}
            >
              {isAttending ? 'Leave' : 'Attend'}
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              disabled={isOver}
              onClick={() => openEditModal(activityId)}
            >
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item width="100%">
          <Typography variant="body2">
            Attending:{' '}
            <span style={{ fontWeight: 700 }}>{attendees.length}</span>
          </Typography>
          <Typography
            component="div"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            variant="caption"
            color="GrayText"
            title={attendeeList}
          >
            {attendees.length ? attendeeList : 'Be the first to attend'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
